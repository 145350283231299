



























































































































































































import { Component, Vue } from "vue-property-decorator";
import { getResultPageList, getResultDetail } from "@/serve/traditionalMedicine/index";
export interface TraditionalForm {
  current?: string | number;
  size?: string | number;
  checkerName?: string;
  checkerIdCard?: string;
  quesStartTime?: any;
  quesEndTime?: any;
  tenantId?: string;
}
@Component({
  name: "evaluationRecord",
  components: {},
})
export default class TraditionalMedicine extends Vue {
  public currentPage4 = 1;
  public form: TraditionalForm = {
    current: 1,
    size: 10,
  };
  //弹框
  public dialogFormVisible = false;
  //详情数据
  public detailList: any = [];
  public isAdmin = sessionStorage.getItem("isAdmin");
  public tenantName = "";
  created() {
    console.log("-----TraditionalMedicine-----");
    //获取问卷列表
    this.obtionList();
  }
  //获取数据
  public obtionList() {
    getResultPageList(this.form).then((res: any) => {

      if (res.code === 200) {
        const data: any = res.data
        data.records.map((item: any) => {
          if(item.scene==="scl-90"){
              console.log("item.derivedContent",item.derivedContent);
          }else{
            item.derivedContent = JSON.parse(item.derivedContent)
          }
        })
        console.log("data,data", data);

        this.tableData = data;
      }
    });
  }
  //表格数据
  public tableData = [];
  // 筛选功能
  public onSubmit() {
    // 点击筛选后 初始化值
    this.currentPage4 = 1;
    this.form.size = 10;
    this.form.current = 1;
    this.obtionList();
  }
  // 重置功能
  public resetForm(formName: string) {
    (this.$refs[formName] as any).resetFields();
    this.currentPage4 = 1;
    this.obtionList();
  }
  public getContent(arr: any[]) {
    let str = "";
    arr.map((i: any) => {
      str += i.schemeContent;
    });
    return str;
  }
  public checkerName = "";
  public detailInfo = {
    checkerName:"",
    questionnaireName:"",
    derivedContent:{
      scoreCount:"",
      resultTitle:"",
      resultDesc:""
    }
  }

  public dialogFormVisibleSCL=false
  //点击查看详细信息
  public readMore(item: any) {
    this.detailInfo = item
    console.log("this.detailInfo",this.detailInfo);
    
    const params: any = {
      resultId: item.id
    }
    getResultDetail(params).then((res: any) => {

      if (res.code === 200) {

        if(res.data.sclDetail){
          console.log("res.data.sclDetail",res.data.sclDetail);
          this.dialogFormVisibleSCL=true
          this.detailList =JSON.parse(res.data.sclDetail) ;
        }else{
          this.dialogFormVisible = true;
          this.detailList = res.data;
        }

        
      }
    });


  }
  // 点击左右箭头
  public handleSizeChange(val: any): void {
    this.form.size = val;
    this.obtionList();
  }
  // 点击页码
  public handleCurrentChange(val: any): void {
    this.form.current = val;
    this.obtionList();
  }
}
